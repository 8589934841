<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('invoices') }}</h3>
  </div>
  <paginated-table-wrapper
    v-if="license"
    :license="license"
    :request="request"
    :is-search="false"
    :csv-handler="downloadCsvHandler"
    :sort="'desc'"
    v-slot="{ list, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table
      v-else
      :license="license"
      :list="list"
    ></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { store } from '@/application/store'
import { getInvoices } from '@/application/services/billing'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formatterCSVInvoices } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'InvoicesPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/InvoicesTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const {
      downloadCsvHandler,
    } = useCsvExport(
      'invoices.csv',
      formatterCSVInvoices,
      {
        invoice_no: i18n.t('forms.billing.rows.number'),
        localized_total_amount: i18n.t('forms.billing.rows.amount'),
        date: i18n.t('forms.billing.rows.dateCreate'),
        status: i18n.t('forms.billing.rows.status'),
        due_date: i18n.t('forms.billing.rows.dateDue'),
        paid_date: i18n.t('forms.billing.rows.datePaid'),
      }
    )
    return {
      license,
      request: getInvoices,
      downloadCsvHandler,
    }
  },
}
</script>
